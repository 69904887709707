import * as React from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// Estilo do switch iOS
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.primary.contrastText, // Usando a cor de texto primária do tema
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.success.main, // Usando a cor de sucesso do tema
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.palette.success.light, // Usando uma cor leve do tema
      border: `6px solid ${theme.palette.background.paper}`, // Cor de fundo do tema
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.action.disabled,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.action.disabledOpacity,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[300], // Cor de fundo neutra do tema
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Componente principal com 7 botões
export default function ButtonRow() {
  const theme = useTheme(); // Utilizando o tema

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={2}
      sx={{ width: '100%', height: '100%' }}
    >
      <Stack
        direction="row"
        spacing={1}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        {[...Array(7)].map((_, index) => (
          <Stack key={index} alignItems="center">
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
              label={`Zona ${index + 1}`}
              labelPlacement="bottom"
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
