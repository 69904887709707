import React, { useEffect, useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Legend from "components/Legend";
import InfoCard from "components/Cards";
import StatBox from "components/StatBox";
import Pressao from "components/Pressao";
import ButtonTrueFalse from "components/ButtonTrueFalse";
import NumericInput from "components/NumericInput";
import NumericInputVirgula from "components/NumericInputVirgula";
import { socket } from "../../socket";
import { Diversity3, Bolt } from "@mui/icons-material";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1000px)");
  const [messageReceived, setMessageReceived] = useState({});

  // Configuração do socket.io
  useEffect(() => {
    socket.on("read", (data) => {
      try {
        const parsedData = JSON.parse(data); // Processa os dados recebidos
        setMessageReceived(parsedData); // Atualiza o estado
        console.log("Dados recebidos e atualizados:", parsedData);
      } catch (error) {
        console.error("Erro ao processar dados do socket:", error);
      }
    });

    return () => {
      socket.off("read"); // Limpa o evento ao desmontar o componente
    };
  }, []);

  return (
    <Box m="0rem 0.5rem">
      {/* GRID PRINCIPAL */}
      <Box
        mt="2px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="151px"
        gap="5px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* COLUNA 1 */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="0.5rem"
          borderRadius="0.55rem"
        >
          <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" rowGap="0.5rem">
            {/* Legendas */}
            <Legend />
            {/* InfoCards Dinâmicos */}
            <InfoCard
              title="Produção"
              programmedValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              actualValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              unit="kg/h"
            />
            <InfoCard
              title="Espessura"
              programmedValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              actualValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              unit="μm"
            />
            <InfoCard
              title="Grama/Metro"
              programmedValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              actualValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              unit="g/m"
            />

            <InfoCard
              title="Vel. Puxador"
              programmedValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              actualValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              unit="m/min"
            />

            <InfoCard
              title="Largura"
              programmedValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              actualValue={messageReceived?.hz?.alimFreqIhm || "N/A"}
              unit="mm"
            />
          </Box>
        </Box>

        {/* COLUNA 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Pressao isDashboard={true} />
        </Box>

        {/* STAT BOXES */}
        <StatBox
          title="Extrusora"
          value={messageReceived?.hz?.alimFreqIhm || "N/A"}
          increase="RPM"
          description="Últimas 24h"
          icon={<Diversity3 sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />}
          ligado={messageReceived?.comandFeeder?.automaticoAlimentadorOne ?? false}
        />
        <StatBox
          title="Puxador"
          value={messageReceived?.hz?.alimFreqIhm || "N/A"}
          increase="m/min"
          description="Agora"
          icon={<Bolt sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />}
          ligado={messageReceived?.comandFeeder?.automaticoAlimentadorOne ?? false} // Adiciona lógica específica
        />
          <StatBox
            title="Dosador"
            value={messageReceived?.comandFeeder?.automaticoAlimentadorOne ? "Ligado" : "Desligado"}
            increase={messageReceived?.comandFeeder?.automaticoAlimentadorOne ? "Ligado" : "Desligado"} // Altera o texto dinamicamente
            description="Agora"
            icon={<Bolt sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />}
            ligado={messageReceived?.comandFeeder?.automaticoAlimentadorOne ?? false} // Adiciona lógica específica
          />


        {/* COLUNA 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
          sx={{
            position: "relative",
          }}
        >
          <ButtonTrueFalse
            tag="Puxador"
            socketVariavel={false}
            sx={{
              position: "relative",
              top: "10px",
              left: "10px",
              zIndex: 10,
            }}
          />
          <NumericInputVirgula />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            sx={{
              width: "100%",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <NumericInput />
            <NumericInput />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
