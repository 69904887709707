import React, { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Typography, useTheme } from "@mui/material";

const TemperaturaColuna = ({ tempReal, tempSetPoint, isDashboard = false }) => {
    const theme = useTheme();

    // Estado para armazenar os dados locais
    const [localTempReal, setLocalTempReal] = useState(null);
    const [localTempSetPoint, setLocalTempSetPoint] = useState(null);

    // Atualiza os estados locais quando as props mudam
    useEffect(() => {
        if (tempReal && tempSetPoint) {
            setLocalTempReal(tempReal);
            setLocalTempSetPoint(tempSetPoint);
        }
    }, [tempReal, tempSetPoint]);

    const isDataAvailable = localTempReal && localTempSetPoint && Object.keys(localTempReal).length > 0 && Object.keys(localTempSetPoint).length > 0;
    const zones = ["zoneOne", "zoneTwo", "zoneThree", "zoneFour", "zoneFive", "zoneSix", "zoneSeven", "zoneEight"];

    const dataReal = isDataAvailable ? zones.map(zone => localTempReal[zone]) : [];
    const dataSetPoint = isDataAvailable ? zones.map(zone => localTempSetPoint[zone]) : [];

    return (
        <Box
            height={isDashboard ? "400px" : "100%"}
            width={undefined}
            minHeight={isDashboard ? "325px" : undefined}
            minWidth={isDashboard ? "325px" : undefined}
            position="relative"
        >
            {isDataAvailable ? (
                <BarChart
                    xAxis={[{ scaleType: 'band', data: zones.map((zone, index) => `Zona ${index + 1}`) }]}
                    series={[
                        { label: 'Temp. Real', data: dataReal },
                        { label: 'SetPoint', data: dataSetPoint }
                    ]}
                    sx={{
                        '& .MuiChart-root': {
                            height: '100%',
                            width: '100%',
                        }
                    }}
                    barLabel="value"
                    colors={['#ff0000', '#0000ff']}  // Vermelho para Temp. Real, Azul para SetPoint
                />
            ) : (
                <Typography variant="h6" align="center">
                    Carregando...
                </Typography>
            )}
        </Box>
    );
}

export default TemperaturaColuna;
