import { io } from 'socket.io-client';

// Utilize o URL público do seu servidor
const URL = process.env.NODE_ENV === 'production' ? 'wss://rvtconnect.com.br' : 'wss://rvtconnect.com.br';

export const socket = io(URL, {
    autoConnect: false,
    secure: true, // Use se estiver usando HTTPS
    transports: ['websocket', 'polling'], // Para garantir a compatibilidade em diferentes ambientes
});
