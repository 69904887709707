import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { socket } from '../../socket';
import {
  DownloadOutlined,
  Timeline,
  BrightnessAuto,
  Diversity3,
  Bolt,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import Temperaturas from "components/Temperaturas";
import TemperaturaColuna from "components/TemperaturaColuna";
import TemperaturaSetPoint from "components/TemperaturaSetPoint";
import ButtonRow from "components/BotoesTemperatura";
import Pressao from "components/Pressao";


const Temperature= () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { data, isLoading } = useGetDashboardQuery();
  const [messageReceived, setMessageReceived] = useState({});
  const [transformedData, setTransformedData] = useState([]); // Novo estado para armazenar o transformedData

  socket.on("read", (data) => {
    const parsedData = JSON.parse(data);
    setMessageReceived(parsedData);
    console.log(`parseData.message ${parsedData} \n Evento read`);
  });

  useEffect(() => {
    console.log('messageReceived:', messageReceived);
  }, [messageReceived]);

 
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Temperaturas"  />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(24, 1fr)"
        gridAutoRows="40px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 24" },
        }}
      >


        <Box
          gridColumn="span 24"
          gridRow="span 6"
          backgroundColor={theme.palette.background.alt}
          p="0.5rem"
          borderRadius="0.55rem"
        >
          <Pressao  isDashboard={true} />
        </Box>
      



        <Box
          gridColumn="span 12"
          gridRow="span 10"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
           Desvios de Temperaturas
          </Typography>
          <Temperaturas 
          tempReal={messageReceived.tempReal} 
          tempSetPoint={messageReceived.tempSetPoint} />
          

        </Box>

        <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={theme.palette.background.alt}
          p="0.5 rem"
          borderRadius="0.1rem"
        >
          <TemperaturaColuna
          tempReal={messageReceived.tempReal} 
          tempSetPoint={messageReceived.tempSetPoint} />
          

        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="0.1rem"
          borderRadius="0.1rem"
        >
          <TemperaturaSetPoint tempSetPoint={messageReceived.tempSetPoint} />
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="0 rem"
          borderRadius="0.1rem"
        >
          <ButtonRow />
        </Box>
       

        
      </Box>
    </Box>
  );
};

export default Temperature;
