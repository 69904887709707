import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import React, { useRef, useEffect, Suspense } from 'react';

// Componente para carregar e renderizar o modelo GLB
const Model = ({ path }) => {
  const { scene, materials, cameras } = useGLTF(path);
  const { gl, set } = useThree();
  const materialRefs = useRef([]);

  useEffect(() => {
    materialRefs.current = Object.values(materials);

    // Imprime todos os materiais no console
    console.log('Materiais carregados:', materials);

    // Se houver uma câmera no modelo, use-a
    if (cameras && cameras.length > 0) {
      const camera = cameras[0];
      set({ camera });
      gl.camera = camera;
    }
  }, [materials, cameras, gl, set]);

  // Hook de animação para fazer o material piscar em vermelho
  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const blink = Math.sin(time * 5) > 0; // Altera entre true e false rapidamente

    if (materials.Ventilador4) {
      materials.Ventilador4.color.set(blink ? 'green' : '#000000'); // Pisca entre vermelho e preto
    }
  });

  return <primitive object={scene} />;
};

// Componente ModelViewer que encapsula todo o Canvas e suas configurações
const ModelViewer = ({ modelPath }) => {
  return (
    <Canvas>
      {/* Luz ambiente com maior intensidade */}
      <ambientLight intensity={0.5} />
      
      {/* Luzes direcionais de diferentes direções */}
      <directionalLight position={[2, 2, 2]} intensidade={1} />
      <directionalLight position={[-2, -2, 2]} intensidade={1} />
      <directionalLight position={[2, -2, 2]} intensidade={1} />
      <directionalLight position={[-2, 2, -2]} intensidade={1} />

      {/* HemisphereLight para iluminação uniforme */}
      <hemisphereLight skyColor={"white"} groundColor={"gray"} intensity={0.5} />

      {/* OrbitControls permite a interatividade com o mouse */}
      <OrbitControls enableZoom={true} />

      <Model path={modelPath} /> {/* O caminho do modelo é passado como prop */}
    </Canvas>
  );
}

// Wrapper que usa Suspense e Lazy Loading para o próprio componente ModelViewer
const ModelViewerWrapper = ({ modelPath }) => {
  return (
    <Suspense fallback={<div>Carregando modelo 3D...</div>}>
      <ModelViewer modelPath={modelPath} />
    </Suspense>
  );
}

export default ModelViewerWrapper;
