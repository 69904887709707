import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "./FlexBetween";
import { socket } from "../socket";

const StatBox = ({ title, value, increase, icon, description, ligado }) => {
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    // Adiciona o efeito visual de clique
    setIsClicked(true);

    // Remove o efeito após 300ms (tempo suficiente para a animação)
    setTimeout(() => setIsClicked(false), 500);

    // Envia a mensagem com o estado invertido de 'ligado'
    const data = { [title]: !ligado };
    socket.emit("mensagem", data);
    console.log("Mensagem enviada via socket.io:", data);
  };

  return (
    <Box
      gridColumn="span 2"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={
        isClicked
          ? theme.palette.success.light // Cor temporária ao clicar
          : ligado
          ? theme.palette.secondary[900] // Verde escuro
          : theme.palette.primary[500] // Azul escuro
      }
      borderRadius="0.55rem"
      onClick={handleClick} // Evento de clique
      sx={{
        cursor: "pointer", // Indica que o componente é clicável
        transform: isClicked ? "scale(0.95)" : "scale(1)", // Efeito de escala
        transition: "transform 0.2s ease, background-color 0.2s ease", // Suaviza a animação
        "&:hover": {
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Efeito de hover
        },
      }}
    >
      {/* Cabeçalho com título e ícone */}
      <FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
          {title}
        </Typography>
        {icon}
      </FlexBetween>

      {/* Valor principal */}
      <Typography
        variant="h3"
        fontWeight="600"
        sx={{ color: theme.palette.secondary[200] }}
      >
        {value}
      </Typography>

      {/* Linha de aumento e descrição */}
      <FlexBetween gap="1rem">
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: theme.palette.secondary.light }}
        >
          {increase}
        </Typography>
        <Typography>{description}</Typography>
      </FlexBetween>
    </Box>
  );
};

export default StatBox;
