import React from "react";

const Alarmes = () => {

    return(
        <div>
        {"Olá"}
        </div>

    )
};
export default Alarmes;