import React, { useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Warning as WarningIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import {
  AppBar,
  IconButton,
  Toolbar,
  Box,
  Collapse,
  Alert,
  Button,
} from "@mui/material";

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(true); // Controla a exibição do alerta

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", position: "relative" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* Botão para alternar o modo (claro/escuro) */}
          <IconButton onClick={() => dispatch(setMode())}>
            <LightModeOutlined sx={{ fontSize: "25px" }} />
          </IconButton>
        </FlexBetween>

        {/* Alerta de Perigo */}
        {showAlert && (
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              left: isSidebarOpen ? "100px" : "50%",
              transform: isSidebarOpen ? "none" : "translateX(-50%)",
              width: isSidebarOpen ? "calc(100% - 200px)" : "100%", // Reduz o comprimento com sidebar aberto
              maxWidth: "800px", // Limita a largura máxima
              zIndex: 1300, // Garante que o alerta fique acima de outros elementos
            }}
          >
            <Collapse in={showAlert}>
              <Alert
                severity="error"
                icon={<WarningIcon />}
                action={
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => setShowAlert(false)}
                    >
                      Undo
                    </Button>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowAlert(false)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </>
                }
                sx={{
                  backgroundColor: "#d32f2f", // Vermelho fixo
                  color: "#fff", // Texto branco
                  "& .MuiAlert-icon": {
                    color: "#fff", // Ícone branco
                  },
                }}
              >
                This file was successfully deleted
              </Alert>
            </Collapse>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
