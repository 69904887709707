import styles from './Login.module.css'
import { useAuthentication } from '../../hooks/useAuthentication'
import React, { useEffect, useState } from 'react'


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] =useState('');
  const [error, setError] = useState('');
  const {login, error: authError, loading} = useAuthentication();//desconstroi o objeto
  
  
const handleSubmit = async (e) =>{
  e.preventDefault();
  setError('');
  const user = {   ///grava vaiáveis do usuário
    email,
    password
  }

  const res = await login(user);

}
useEffect(()=>{if(authError){setError(authError);}},[authError]);

return (
  <div className={styles.box}>
    <h1>Entrar</h1>
    <p>Faça o login para utilizar o sistema</p>
    <form onSubmit={handleSubmit}>
      <label>
        <span>E-mail:</span>
        <input
          type="email"
          name="email"
          required
          placeholder="E-mail do usuário"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </label>
      <label>
        <span>Senha:</span>
        <input
          type="password"
          name="password"
          required
          placeholder="Insira sua senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      {!loading ? (
        <button className="btn">Entrar</button>
      ) : (
        <button className="btn" disabled>
          Aguarde ...
        </button>
      )}
      {error && <p className="error">{error}</p>}
    </form>
  </div>
);
};


export default Login