// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Register_register__H6uKZ{\n    text-align: center;\n\n}\n\n.Register_register__H6uKZ p{\n    color: #aaa;\n}", "",{"version":3,"sources":["webpack://./src/pages/Register/Register.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".register{\n    text-align: center;\n\n}\n\n.register p{\n    color: #aaa;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"register": "Register_register__H6uKZ"
};
export default ___CSS_LOADER_EXPORT___;
