import React from "react";

const Producao = () =>{
    return(
        <div>
            {"Olá Produção"}
        </div>
    )
}

export default Producao