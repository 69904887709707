import React, { useEffect, useRef } from 'react';
import ezuikit from 'ezuikit-js';

const EZUIKitPlayer = () => {
  // useRef é usado para obter uma referência ao elemento DOM onde o player será anexado
  const playerContainerRef = useRef(null);

  useEffect(() => {
    // Verifica se o EZUIKit está disponível no escopo global
    if (!window.EZUIKit) {
      console.error('EZUIKit não está disponível');
      return;
    }

    // Certifique-se de que a referência está disponível e a biblioteca está carregada antes de inicializar o player
    if (playerContainerRef.current) {
      const player = new window.EZUIKit.EZUIKitPlayer({
        id: playerContainerRef.current.id, // Usa o ID atual da referência
        accessToken: "at.cjn0pui13ozxxjt02zyvc9av5ddvcps2-5tqybcsfpk-1vykw5i-is2c7g3fr",
        url: "ezopen://170166@open.ezviz.com/L56027261/1.hd.live",
        template: 'simple',
        plugin: ['talk'],
        env: {
          domain: "https://isaopen.ezvizlife.com"
        }
      });

      // Função de limpeza para evitar vazamentos de memória
      return () => {
        if (player) {
          player.destroy();
        }
      };
    }
  }, []); // O array de dependências vazio faz com que o efeito execute apenas uma vez após a renderização inicial

  // Retorna o contêiner do player, que ajustará automaticamente o tamanho com base no contêiner pai
  return <div ref={playerContainerRef} id="video-container" style={{ width: '100%', height: '100%' }} />;
};

export default EZUIKitPlayer;
