import React, { useState } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import { socket } from '../../socket';
import { useAuthValue } from 'context/AuthContext';

const Pressure = () => {
  const { user } = useAuthValue();
  console.log("Email do usuário: " + user.email);

  // States para os valores de entrada
  const [kgh, setKgh] = useState('');
  const [mMin, setMMin] = useState('');
  const [matriz, setMatriz] = useState('');
  const [rs, setRs] = useState('');
  const [messageReceived, setMessageReceived] = useState('');

  const sendMessage = () => {
    // Formata os dados em um objeto
    const data = {
      kgh,
      mMin,
      matriz,
      rs,
    };

    // Envia os dados via socket
    socket.emit("mensagem", data);
    console.log("Mensagem enviada", data);
  };

  // Recebe dados via socket
  socket.on("read", (data) => {
    const parsedData = JSON.parse(data);
    setMessageReceived(parsedData.temperatura);
    console.log(`parseData.message ${parsedData.message} \n Evento read`);
  });

  return (
    <div className="App">
      <Typography variant="h5" gutterBottom>
        Painel de Controle
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField 
            label="Kgh" 
            variant="filled" 
            fullWidth 
            value={kgh} 
            onChange={(e) => setKgh(e.target.value)} 
          />
        </Grid>
        <Grid item xs={3}>
          <TextField 
            label="m/min" 
            variant="filled" 
            fullWidth 
            value={mMin} 
            onChange={(e) => setMMin(e.target.value)} 
          />
        </Grid>
        <Grid item xs={3}>
          <TextField 
            label="Matriz" 
            variant="filled" 
            fullWidth 
            value={matriz} 
            onChange={(e) => setMatriz(e.target.value)} 
          />
        </Grid>
        <Grid item xs={3}>
          <TextField 
            label="R.S" 
            variant="filled" 
            fullWidth 
            value={rs} 
            onChange={(e) => setRs(e.target.value)} 
          />
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={sendMessage} 
            fullWidth
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Teste de envio de mensagens via socket.IO:
      </Typography>
      <Typography variant="body1">
        {messageReceived}
      </Typography>
    </div>
  );
}

export default Pressure;
