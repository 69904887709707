import React from "react";

const Calibragem = () =>{

    return(
        <div>
            {"Olá"}
        </div>
    )
}

export default Calibragem