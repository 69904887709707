import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

export default function TemperaturaSetPoint({ tempSetPoint, onSetPointChange }) {
  const theme = useTheme();

  const [values, setValues] = React.useState({
    Z1: tempSetPoint?.zoneOne || 0,
    Z2: tempSetPoint?.zoneTwo || 0,
    Z3: tempSetPoint?.zoneThree || 0,
    Z4: tempSetPoint?.zoneFour || 0,
    Z5: tempSetPoint?.zoneFive || 0,
    Z6: tempSetPoint?.zoneSix || 0,
    Z7: tempSetPoint?.zoneSeven || 0,
  });

  React.useEffect(() => {
    setValues({
      Z1: tempSetPoint?.zoneOne || 0,
      Z2: tempSetPoint?.zoneTwo || 0,
      Z3: tempSetPoint?.zoneThree || 0,
      Z4: tempSetPoint?.zoneFour || 0,
      Z5: tempSetPoint?.zoneFive || 0,
      Z6: tempSetPoint?.zoneSix || 0,
      Z7: tempSetPoint?.zoneSeven || 0,
    });
  }, [tempSetPoint]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    const min = 0; // Valor mínimo
    const max = 300; // Valor máximo

    if (value === "" || (Number(value) >= min && Number(value) <= max)) {
      const newValues = {
        ...values,
        [id]: value,
      };
      setValues(newValues);

      // Cria o objeto atualizado para enviar via socket.io
      const updatedSetPoint = {
        zoneOne: newValues.Z1,
        zoneTwo: newValues.Z2,
        zoneThree: newValues.Z3,
        zoneFour: newValues.Z4,
        zoneFive: newValues.Z5,
        zoneSix: newValues.Z6,
        zoneSeven: newValues.Z7,
      };

      onSetPointChange(updatedSetPoint);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 0.5,
        justifyContent: "space-between",
        "& .MuiTextField-root": {
          flexBasis: "calc(100% / 7 - 12px)",
          maxWidth: "calc(100% / 7 - 12px)",
          minWidth: 60,
          marginBottom: 1,
        },
        "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& input[type='number']": {
          MozAppearance: "textfield",
        },
        padding: 2,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
      }}
      noValidate
      autoComplete="off"
    >
      {Array.from({ length: 7 }, (_, index) => (
        <TextField
          key={`Z${index + 1}`}
          id={`Z${index + 1}`}
          label={`Z${index + 1}`}
          type="number"
          value={values[`Z${index + 1}`]}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 0,
            max: 300,
            step: 1,
          }}
          sx={{
            flexBasis: "calc(100% / 7 - 12px)",
            maxWidth: "calc(100% / 7 - 12px)",
            minWidth: 60,
            marginRight: 0.01,
          }}
        />
      ))}
    </Box>
  );
}
