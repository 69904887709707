import React from "react";

const Config = () =>{
    return(
        <div>
            {"Olá Config"}
        </div>
    )
}

export default Config