import React, { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { socket } from '../../socket';
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  QueryBuilder,
  Timeline,
  BrightnessAuto,
  CurrencyExchangeTwoTone,
  Diversity3,
  Bolt,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import Temperaturas from "components/Temperaturas";
import EZUIKitPlayer from '../../components/Liveview';
import Motores from "components/Motores";
import Pressao from "components/Pressao";
import { dataMotors } from "data/data";

const Controle= () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { data, isLoading } = useGetDashboardQuery();
  const [messageReceived, setMessageReceived] = useState({});
  const [transformedData, setTransformedData] = useState([]); // Novo estado para armazenar o transformedData

  socket.on("read", (data) => {
    const parsedData = JSON.parse(data);
    setMessageReceived(parsedData);
    console.log(`parseData.message ${parsedData} \n Evento read`);
  });

  useEffect(() => {
    console.log('messageReceived:', messageReceived);
  }, [messageReceived]);

  const transformData = (messageReceived) => {
    if (!messageReceived) return [];
  
    const motorCurrent = messageReceived.motorCurrent || {};
    const motorSpeed = messageReceived.motorSpeed || {};
    const motorSetPoint = messageReceived.motorSetPoint || {};
    const motorStatus = messageReceived.motorStatus || {};
    const hz = messageReceived.hz || {};
  
    return [
      { id: 1, motores: "Extrusora", type: "RPM", name: motorSpeed.extrusoraVelocidadeIhm, speed: motorSpeed.extrusoraVelocidadeIhm, corrente: motorCurrent.extrusoraCorrenteIhm },
      { id: 2, motores: "Puxador 1", type: "m/min", name: motorSpeed.puxOneVelocidadeIhm, speed: motorSpeed.puxOneVelocidadeIhm, corrente: motorCurrent.puxOneCorrenteIhm },
      { id: 3, motores: "Puxador 2", type: "%", name: motorSpeed.puxTwoVelocidadeIhm, speed: motorSpeed.puxTwoVelocidadeIhm, corrente: motorCurrent.puxTwoCorrenteIhm },
      { id: 4, motores: "Bobinador 1", type: "%", name: motorSetPoint.bobOneRefIhm, speed: motorSpeed.bobOneVelocidadeIhm, corrente: motorCurrent.bobOneCorrenteIhm },
      { id: 5, motores: "Bobinador 2", type: "%", name: motorSetPoint.bobTwoRefIhm, speed: motorSpeed.bobTwoVelocidadeIhm, corrente: motorCurrent.bobTwoCorrenteIhm },
      { id: 6, motores: "Anel de Ar", type: "Hz", name: hz.alimFreqIhm, speed: hz.alimFreqIhm, corrente: motorCurrent.alimCorrenteIhm },
      { id: 7, motores: "Giratório", type: "on/off", name: motorStatus.alimStatusIhm, speed: motorStatus.alimStatusIhm, corrente: motorCurrent.alimCorrenteIhm }
    ];
  };

  useEffect(() => {
    if (messageReceived) {
      const transformedData = transformData(messageReceived);
      setTransformedData(transformedData); // Atualiza o estado com os dados transformados
      console.log('Transformed Data:', transformedData);
    }
  }, [messageReceived]);

  const columns = [
    {
      field: "motores",
      headerName: "Motores",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Set Point",
      flex: 0.5,
    },
    {
      field: "type",
      headerName: "Valor",
      flex: 0.5,
    },
    {
      field: "speed",
      headerName: "Velocidade Real",
      flex: 1,
    },
    {
      field: "corrente",
      headerName: "Corrente %",
      flex: 0.5,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Estatísticas de Produção" subtitle="Acompanhe as métricas mais importantes de produção e operação" />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Pressão"
          value={messageReceived?.pressure?.fdPressaoExtrusoraIhm || 'N/A'}
          increase = "Bars"
          description="Agora"
          icon={
            <Timeline
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
       <StatBox
         title="Corrente"
        value={messageReceived?.motorCurrent?.extrusoraCorrenteIhm || 'N/A'}
        increase="Porcentagem"
        icon={
        <BrightnessAuto
        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
    />
  }
/>
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Pressao  isDashboard={true} />
        </Box>
        <StatBox
          title="Produção Aproximada"
          value="1.000 t"/*{data && data.thisMonthStats.totalSales}*/
          increase="+5%"
          description="Ultimas 24h"
          icon={
            <Diversity3
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Consumo"
          value="60KW"/*{data && data.yearlySalesTotal}*/
          increase="Kw/h"
          description="Agora"
          icon={
            <Bolt
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        {/* ROW 2 */}
        <Box
          gridColumn="span 7"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[500],
              borderBottom: "none",
              fontSize: "15px", // Defina o tamanho da fonte para os cabeçalhos de coluna
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              color:theme.palette.secondary[100],
              fontSize: "15px",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[500],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !transformedData}
            getRowId={(row) => row.id}
            rows={transformedData || []}
            columns={columns}
          />
        </Box>
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
           Desvios de Temperaturas
          </Typography>
          <Temperaturas 
          tempReal={messageReceived.tempReal} 
          tempSetPoint={messageReceived.tempSetPoint} />
          

        </Box>

        
      </Box>
    </Box>
  );
};

export default Controle;
