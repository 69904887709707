import React from "react";

const Receitas = () =>{
    return(
        <div>
            {"Olá Receitas"}
        </div>
    )
}

export default Receitas