
import React, { useState, useEffect } from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import { Box, Typography, useTheme } from "@mui/material";

const Temperaturas = ({ tempReal, tempSetPoint, isDashboard = false }) => {
    const theme = useTheme();

    // Definindo o estado inicial como null/undefined antes dos dados chegarem via socket.
    const [localTempReal, setLocalTempReal] = useState(null);
    const [localTempSetPoint, setLocalTempSetPoint] = useState(null);

    // Atualizando o estado local quando novos dados chegarem através das props.
    useEffect(() => {
        if (tempReal && tempSetPoint) {
            setLocalTempReal(tempReal);
            setLocalTempSetPoint(tempSetPoint);
        }
    }, [tempReal, tempSetPoint]);

    const isDataAvailable = localTempReal && localTempSetPoint && Object.keys(localTempReal).length > 0 && Object.keys(localTempSetPoint).length > 0;
    const zones = ["zoneOne", "zoneTwo", "zoneThree", "zoneFour", "zoneFive", "zoneSix", "zoneSeven", "zoneEight"];

    const data = isDataAvailable && zones.map((zone, index) => ({
        "Zona": `Zona ${index + 1}`,
        "Temp. Real": localTempReal[zone],
        "SetPoint": localTempSetPoint[zone]
    }));

    return (
        <Box
            height={isDashboard ? "400px" : "100%"}
            width={undefined}
            minHeight={isDashboard ? "325px" : undefined}
            minWidth={isDashboard ? "325px" : undefined}
            position="relative"
        >
            {isDataAvailable ? (
                <ResponsiveRadar
                    data={data}
                    theme={{
                        axis: {
                            domain: {
                                line: {
                                    stroke: theme.palette.secondary[200],
                                },
                            },
                            legend: {
                                text: {
                                    fill: theme.palette.secondary[200],
                                    fontSize: 12,
                                },
                            },
                            ticks: {
                                line: {
                                    stroke: theme.palette.secondary[200],
                                    strokeWidth: 1,
                                },
                                text: {
                                    fill: theme.palette.secondary[200],
                                    fontSize: 12,
                                },
                            },
                        },
                        legends: {
                            text: {
                                fill: theme.palette.secondary[200],
                                fontSize: 12,
                            },
                        },
                        tooltip: {
                            container: {
                                color: theme.palette.primary.main,
                            },
                        },
                    }}
                    keys={['Temp. Real', 'SetPoint']}
                    indexBy="Zona"
                    valueFormat=">-.2f"
                    margin={{ top: 40, right: 60, bottom: 60, left: 60 }}
                    borderColor={{ from: 'color' }}
                    gridLevels={6}
                    gridLabelOffset={10}
                    gridShape='linear'
                    dotSize={8}
                    dotColor={{ theme: 'background' }}
                    dotBorderWidth={3}
                    dotLabel={10}
                    fillOpacity={0.7}
                    enableDotLabel={true}
                    colors={{ scheme: 'set1' }}
                    blendMode="lighten"
                    motionConfig="wobbly"
                    legends={[
                        {
                            anchor: 'top-right',
                            direction: 'column',
                            translateX: -55,
                            translateY: -30,
                            itemWidth: 100,
                            itemHeight: 30,
                            itemTextColor: theme.palette.secondary[200],
                            symbolSize: 16,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                />
            ) : (
                <Typography variant="h6" align="center">
                    Carregando...
                </Typography>
            )}
        </Box>
    );
}

export default Temperaturas;

